.Commonfullformblock {
  margin: 0 auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
  padding: 30px 40px;
  text-align: center;
}

.Commonfull-icon .successImg {
  /* background:#27ae60; */
  width: 70px;
  height: 70px;
  padding: 15px 11px;
  border-radius: 50%;
  /* margin-bottom:20px; */
}

.Commonfull-icon .errorImg {
  /* background: #ed1b2e; */
  width: 50px;
  height: 50px;
  padding: 12px 12px;
  border-radius: 50%;
  /* margin-bottom: 20px; */
}

.Commonfull-icon .warningImg {
  background: #ed1b2e;
  width: 50px;
  height: 50px;
  padding: 12px 12px;
  border-radius: 50%;
  /* margin-bottom: 20px; */
}

.Commonfullformblock .get-started-btn {
  margin-left: 4px;
  color: #fff;
  border-radius: 5px;
  padding: 6px 25px 7px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 20px;
  display: inline-block;
  font-weight: 300;
  background: #bd062c;
}

.CommonModalbody h2 {
  color: rgb(0, 39, 104);
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.CommonModalbody p {
  color: rgb(112, 112, 112);
  font-size: 16px;
  /* margin-bottom:20px; */
}

.CommonModalfooter {
  margin-top: 20px;

}

.CommonModalfooter.session button:first-child {
  margin-right: 16px;
}

.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}

.CommonModels-box {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: auto;
  max-height: 150vh;
  margin-top: 20vh;
  border-radius: 4px;
  overflow: auto;
  /* top: 100px; */
  top: 0px;
  max-width: 800px;
}

/* .CommonModels-box h2{
    margin-bottom: 20px !important;
  } */

button.cta-btn.btn-smlprimary {
  background: #fff;
  color: #bd062c;
  border: 1px solid #bd062c;
  padding: 8px 30px;
}

/* .CommonModels-box .red-star{
margin-bottom: 10px;
} */

a.btn.btn-smlprimary {
  color: #ffffff;
  background: #bd062c;
}

button.btn.btn-smlprimary {
  color: #ffffff;
  background: #bd062c;
}