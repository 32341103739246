* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}


*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

table a {
  color: #0d6efd !important;
  text-decoration: underline !important;
  cursor: pointer;
}

/* ============= home about section starts=============== */
.wrapper {
  position: relative;
  width: 100%;
  /*overflow: auto;*/
  /* height: 120%; */
}

.wrapper1 {
  position: relative;
  width: 100%;
  /* overflow: auto; */
  overflow-x: 1200px;
  overflow-y: auto;
}
/* .wrapper-second{
  position: relative;
  width: 100%;
  overflow: auto;
  /* height: 100%; */
/* } 







/* ---------------------------------------------------
    SIDEBAR STYLE start
----------------------------------------------------- */



#sidebar {
  position: fixed;
  height: 100vh !important;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  width: 260px;
  overflow: auto;
  transition: all 0.3s;
  background: #002768;
  box-shadow: 0 10px 30px -16px rgb(0 0 0 / 42%);
 
  /* cursor: pointer; */
}


#sidebar::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
  background-color: #eee;
  display: none;
 
}

#sidebar::-webkit-scrollbar-thumbs {
  width: 5px;
  border-radius: 10px;
  background-color: #333;
  display: none;
}


#sidebar:hover::-webkit-scrollbar {
  display: block;
}

#sidebar:hover::-webkit-scrollbar-thumbs {
  display: block;
}

#sidebar .sidebar-header {
  padding: 10px !important;
  background-color: #ffffff;
  border-bottom: 1px solid #eee;
  text-align: center;
  cursor: pointer;
  height: 80px;
}

.active .sidebar-header {
  padding: 10px !important;
  text-align: center;
}

.sidebar-header h3 {
  color: #333;
  font-size: 17px;
  margin: 0px;
  text-transform: uppercase;
  transition: all 0.5s ease;
  font-weight: 600;
}

.sidebar-header h3 img {
  width: 45px;
  margin-right: 10px;
}

#sidebar ul li {
  padding: 2px 7px;
  cursor: pointer;
}

#sidebar ul li.active>a {
  color: #ffffff;
  background-color: #bd062c;
  border-left-style: solid;
  border-left-color: #bd062c;
  border-left-width: px;
  font-weight: 600;
}

#sidebar .active i {
  color: #ffffff !important;

}

#sidebar i {
  color: #ffffff !important;

}

#sidebar ul li .active i {
  color: #ffffff !important;

}


#sidebar ul li a:hover {
  background-color: #bd062c;
  color: #ffffff;
}

#sidebar ul li a {
  color: #c2c2c2;
}



#sidebar ul li.drodown {
  position: sticky;
}


#sidebar ul.components {
  padding: 20px 0;
}

.active .components i {
  left: 50%;
  transform: translateX(-50%);
  margin: 0 !important;


}

#sidebar ul li a {
  padding: 8px;
  line-height: 30px;
  border-radius: 5px;
  font-size: 14px;
  position: relative;
  font-weight: 400;
  display: block;
}

#sidebar ul li a span {
  text-transform: capitalize;
  display: inline-block;
}

#sidebar ul li a i {
  position: relative;
  margin-right: 10px;
  color: #555555;
  margin-left: 10px;
  font-size: 16px;
}

.header-icon i {
  font-size: 20px;
  color: #9E9E9E;
}

/* ---------------------------------------------------
 sidebar end
----------------------------------------------------- */

/*=======================================================
   main-content navbar-design start
   ===============================================*/



#content {
  position: relative;
  transition: all 0.3s;
  background-color: #EEEEEE;
}

.top-navbar {
  width: 100%;
  z-index: 9;
  position: relative;
  box-shadow: 0 10px 10px -15px rgb(0 0 0 / 42%);
}

.main-content {
  padding: 10px;
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #F8FAFA;
}
/* dashboard */
.main-content-dash
{
  padding: 20px;
  position: relative;
  width: 100%;
  height: 75vh; 
  background-color: #F8FAFA;
}

/* calls */
.main-content-call
{
  padding: 20px;
  position: relative;
  width: 100%;
  height: 75vh; 
  background-color: #F8FAFA;
}
/* report */
.main-content-report
{
  padding: 5px;
  position: relative;
  width: 100%;
  height: 60vh; 
  background-color: #F8FAFA;
}

.modal-body.map {
  height: 400px;
}

ul.nav.navbar-nav.ml-auto {
  align-items: center;
}

.navbar {
  background-color: #ffffff;
  color: #FFFFFF;
}

.navbar-brand {
  color: #FFFFFF;
}

.navbar button {
  background-color: transparent;
  border: none;
}

.navbar button span {
  color: #7c7272;
}

.nav-link:focus,
.nav-link:hover {
  color: #555555;
}

#sidebarCollapse {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  /* margin-right: 20px; */
  border: none;
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.09);
}

#sidebarCollapse :hover {
  background: #002768;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  /* margin-right: 20px; */
  border: none;
  color: #FFFFFF;
  margin: 0px;
  padding: 0px;
}

#sidebarCollapse span {
  margin: 9px;
  padding: 0px;
}


.navbar-nav>li.active {
  color: #FFFFFF;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.08);
}

a.nav-link {
  color: #263238;
}

.navbar .notification {
  position: absolute;
  color: #ffffff;
  top: 0;
  right: -2px;
  display: block;
  font-size: 9px;
  border: 0px;
  font-size: 10px;
  background: #d9534f;
  min-width: 18px;
  text-align: center;
  height: 18px;
  border-radius: 30px;
  line-height: 18px;
}


.navbar-nav>li.show .dropdown-menu {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
}


.dropdown-menu li>a {
  font-size: 13px;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 2px;
  font-weight: 400;
  transition: all 150ms linear;
}

.navbar-nav>.active>a:focus {
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.08);
}


a.mainbutton {
  background: #bd062c;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  padding: 8px 16px;
  cursor: pointer;
}

h4 {
  text-transform: uppercase;
  font-size: 18px;
  color: #06103c;
  margin-bottom: 20px;
}

.dashboard-sec .dash-outer-box {
  /* display: flex; */
  padding: 16px;
  background: #fff;
  margin-bottom: 12px;
  /* align-items: center;
  justify-content: center; */
  border-radius: 8px;
  box-shadow: 0 10px 10px -15px rgb(0 0 0 / 42%);
  /* cursor: pointer; */
}

.dash-outer-box h3 {
  font-size: 14px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 10px;
}

section.dashboard-sec {
  margin-bottom: 18px;
  margin-top: 20px;
}

.box-content h6 {
  font-size: 20px;
  font-weight: 800;
  margin: 0px;
  color: #2F3941;
  margin-bottom: 8px;
  font-family: roboto;
}

.box-content {
  /* padding-right: 50px; */
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.icons {
  padding-top: 5px;
}

.dast-title p {
  margin-bottom: 0;
  font-size: 12px;
  vertical-align: baseline;
}

span.user {
  color: black;
  float: left;
  padding: 0px 8px;
}

.dash-icon {
  border-radius: 50px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.dash-icon-color1 {
  background: #a4d3db;
  color: #0694A2;
}

.dash-icon-color2 {
  background: #cbdeff;
  color: #3F83F8;
}

.dash-icon-color3 {
  background: #ffbea6;
  color: #FF5A1F;
}

.dash-icon-color4 {
  background: #b5f5fc;
  color: #0694A2;
}

.dash-icon-color5 {
  background: #ffbfd0;
  color: #F41750;
}

.dash-icon-color6 {
  background: #d2ffc7;
  color: #23890B;
}

.dashboard-sec .custom-column {
  /* margin-right: 25px;
  margin-left: 60px; */
  width: 40%;
}

.add-btns{
  width: 49%;
  float:left;
  
  
  background-color: #bd062c;
  text-align: center;
  padding: 7px;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
  height: fit-content;
 
}

/* .add-btns:hover{
  background-color: #002768;
  transition:  0.5s;
} */

.add-btns1 {
  width:49%;
  float:right;
  margin-bottom:25px;

  background-color: #002768;
  text-align: center;
  padding: 7px;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
}



/* .add-btns1:hover{
  background-color: green;
  transition:  0.5s;
} */
.add-bt {
  width: 49%;
  float: left;
  margin-bottom: 25px;
  background-color: #002768;
  text-align: center;
  padding: 7px;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
  height: fit-content;
}

.add-bt1 {
  width: 49%;
  float: right;
  margin-bottom: 25px;
  background-color:#bd062c;
  text-align: center;
  padding: 7px;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
  height: fit-content;
}

.add-btn {
  background-color: #bd062c;
  padding: 7px;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
  height: fit-content;
}
.add-section {
  display: flex;
  align-items: center;
  justify-content: end;
  
  /* margin-top: 20px; */
  /* margin-bottom: 30px; */
}

 

.back-btn {
  background-color: #bd062c;
  padding: 7px;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  width: 65px;
  position: absolute;
}
.back-heading {
  position: relative;
}

a.edit i {
  margin-right: 5px;
  font-size: 14px;
}

a.edit {
  color: #ffffff !important;
  text-decoration: none !important;
  border: 1px solid #bd062c;
  background: #bd062c;
  padding: 4px 16px;
  border-radius: 3px;
  cursor: pointer;
}

a.edit:hover {
  color: #ffffff !important;
  border: 1px solid #002768 !important;
  background-color: #002768;
  border-radius: 5px;
  padding: 7px 16px;
}

.common-heading-sec {
  width: 100%;
  display: flex;
  /* margin-bottom: 18px;
  margin-top: 18px; */
}


.common-heading-sec {
  display: flex;
  align-items: center;
  /* padding-left: 0; */
}
span.row-per-page {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}
.common-heading-sec h5 {
  text-transform: uppercase;
  font-size: 18px;
}

.common-heading-sec .search-bar {

}

.common-heading-sec .search-bar-notify {
  margin-bottom: 15px;
  margin-right: 55px;

}

.form-control-notify {
  display: block;
  width: 120%;
  padding: 0.28rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.search-bar {
  margin-right: 10px;
}
/* .datePickerBlock {
  margin-right: 10px;
} */

.common-heading-sec .dropdown-sec {
  margin-bottom: 15px;
  margin-right: 10px;
}

.menus-icon {
  margin: 0 auto;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.menus-icon i {
  font-size: 14px;
}

.custom-table td {
  border: none;
}

table td {
  vertical-align: middle;
}

.list-group-item {
  border: none;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  cursor: pointer;
  font-size: 14px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
}

.table td,
.table th {
  font-size: 14px;
}

.good-color {
  color: #19C958;
  background-color: #d0ffe1a4;
  padding: 4px 16px;
  border-radius: 50px;
}

.per-block-color {
  color: #c91919;
  background-color: #ffd0d0;
  padding: 4px 16px;
  border-radius: 50px;
}

.pending-color {
  color: #c9a019;
  background-color: #fff2d0;
  padding: 4px 16px;
  border-radius: 50px;
}

.block-color {
  color: #1997c9;
  background-color: #d0f4ff;
  padding: 4px 16px;
  border-radius: 50px;
}

.review-color {
  color: #040D58;
  background-color: #283be442;
  padding: 4px 16px;
  border-radius: 50px;
}

span.premium-color {
  color: #19C958;
  background: rgb(201 248 217);
  padding: 4px 16px;
  border-radius: 50px;
  /* cursor: pointer; */
}

span.supreme-color {
  border-radius: 50px;
  color: #c2a031;
  background: rgb(255 246 195);
  padding: 4px 16px;
  /* cursor: pointer; */
}

span.none-color {
  border-radius: 50px;
  color: #d60000;
  background: rgb(255 217 217);
  padding: 4px 16px;
  /* cursor: pointer; */
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border: none;
}

.page-link {
  position: relative;
  display: block;
  color: #6c757d;
  text-decoration: none;
  background-color: #fff;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  border: none;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #bd062c;
  border-radius: 5px;
}

.menu-popups {
  z-index: 9;
  max-width: 250px;
  position: absolute;
  right: 46px;
  top: 20px;
}

div#myDIV {
  display: none;
}

table.table tr {
  position: relative;
}




/*=======================================================
   new-customer
   ===============================================*/
/*responsive*/

@media(max-width: 500px) {
  .table thead {
    display: none;
  }

  .table,
  .table tbody,
  .table tr,
  .table td {
    display: block;
    width: 100%;
  }

  .table tr {
    margin-bottom: 15px;
  }

  .table td {
    text-align: right;
    padding-left: 50%;
    text-align: right;
    position: relative;
  }

  .table td::before {
    content: attr(class);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
  }
}

a.view {
  color: #db0808;
  border: 1px solid #dd0000;
  padding: 2px 10px;
  border-radius: 5px;
}

.pagination {
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}


/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media only screen and (min-width:720px) {
  #sidebar.active {
    width: 80px;
    height: 100% !important;
    position: absolute !important;
    /* overflow: visible !important; */
    top: 0 !important;
    z-index: 666;
    float: left !important;
    bottom: 0 !important;
  }


  #sidebar.active .sidebar-header h3 span {
    display: none;
    transition: all 0.5s ease;
  }

  #sidebar.active ul li a span {
    display: none;
    transition: all 0.5s ease;
  }

  #sidebar.active .dropdown-toggle::after {
    display: none;
    transition: all 0.5s ease;
  }

  #content {
    width: calc(100% - 260px);
    position: relative;
    float: right;
    transition: all 0.3s;
    background-color: #EEEEEE;
  }

  #content.active {
    width: calc(100% - 80px);
  }

  #sidebar.active .menu {
    position: absolute;
    left: 81px;
    background-color: white;
    width: 180px;
    height: auto;
    margin: 5px 0;
    top: 0;
    border: 1px solid #dcd9d9;
    z-index: 4;
  }
}


@media only screen and (min-width:720px) {
  .dropdown-menu {
    border: 0;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
    transform: translate3d(0, -20px, 0);
    /* visibility: hidden; */
    transition: all 150ms linear;
    display: block;
    min-width: 15rem;
    right: 0;
    left: auto;
    opacity: 1;
    top: 64px;
  }

}

.navbar-expand-lg .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
  justify-content: flex-end;
  align-items: center;
}

.header-icon select {
  border: 0;
  cursor: pointer;
}





/*===============small-screen overlay sidebar design media queries==============*/

@media only screen and (max-width:720px) {
  #sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 10;
    width: 260px;
    transform: translatex(-100%);
    transition: all 150ms linear;
    box-shadow: none !important;
  }

  .body-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    visibility: hidden;
    opacity: 0;
    z-index: 3;
    transition: all 150ms linear;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.active .img-fluid {

  display: none;
}

.img-fluid {
  max-width: 70%;
  height: 60px;
}

.img-simpl {
  height: auto;
  max-width: 100%;
  margin-top: 25px;
  display: none;
}

.active .img-simpl {
  display: revert;

}

/*===============small-screen overlay sidebar design media queries==============*/


/* Login page */
.rigt-side-img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.login-right-sec {
  position: relative;
}

.login-right-sec::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.12);
  object-fit: cover;
}

.rigt-side-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px;
}

.login-Left-sec {
  padding: 60px 110px;

}

.login-page h1 {
  text-transform: uppercase;
  color: #263238;
  margin-bottom: 40px;
  font-size: 36px;

}

.btn-login {
  width: 100%;
  background-color: #bd062c;
  border: none;
  color: #ffffff !important;
  transition: 1s;
  padding: 0.7rem 0.75rem;
  text-decoration: none !important;
}

.btn-login:hover {
  width: 100%;
  background-color: #a70123;
  color: #ffffff;
  border: none;
}

.login-page .form-control {
  padding: 0.7rem 0.75rem;
  border: 1px solid rgb(206 212 218 / 38%);
}

.loginpage-logo {
  margin-bottom: 30px;
}

.login-bottom-link {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.login-page .form-control::-webkit-input-placeholder {
  color: #CFCFCF;
}

.login-page .form-label {
  color: #263238;
}

.login-page a {
  color: #19C958;
  text-decoration: underline;
}

.modal-body {
  padding: 1.5rem;
  width: 85%;
  margin-left: 7.5%;
  background: #ffffff;
  box-shadow: 0 0 15px 15px #f1f1f1;
}

.caption-center {
  text-align: center;
}

.modal-title {
  font-size: 18px;
  font-size: 20px;
  text-align: center;
  margin-top: -52px;
}

.modal-header {
  justify-content: center;
  padding: 10px;
  border-bottom: none;
}

.modal-footer {
  justify-content: center;
  border-top: 0px;
}

button.btn.form-submit-secbtn {
  border: 1px solid;
  color: #dc3545;
  border-radius: 5px;
  font-size: 14px;
}

button.btn.form-submit-btn {
  color: #fff;
  background: #19C958;
  border-radius: 5px;
  font-size: 18px;
}

button.btn.form-close-btn {
  color: #fff;
  background: #bd062c;
  border-radius: 5px;
  font-size: 18px;
}

button.btn.form-delete-btn {
  color: #fff;
  background: #dc3545 !important;
  border-radius: 5px;
  font-size: 14px;
}

button.btn.form-submit-editbtn {
  background: rgb(103, 104, 122);
  color: #fff;
  padding: 6px 22px;
  border-radius: 5px;
  font-size: 14px;
}

.edit {
  padding: 10px;

}

.active-color {
  color: #19C958;
  background: #c1ffd7;
  border-radius: 50px;
  padding: 4px 16px;
  /* cursor: pointer; */
}

.inactive-color {
  color: #d60000;
  background: rgb(255 217 217);
  border-radius: 50px;
  padding: 4px 16px;
  /* cursor: pointer; */
}

.slider-container {
  position: relative;
}

.slider-container .bar {
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 10px;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background-color: #aee7bf;
  overflow: hidden;
}

.slider-container .bar .fill {
  display: block;
  width: 0;
  height: 100%;
  background-color: #19C958
}

.slider-container .slider {
  position: relative;
  z-index: 2;
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  outline: none;
  background-color: transparent;
}

.slider-container .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 30px;
  height: 30px;
  background-color: #19c958;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  transition: 0.3s ease-in-out;
}

.controlls {
  width: 75%;
  margin-top: 2.5em;
}

.progress_part {
  width: 100%;
  margin-bottom: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.progress_part p {
  color: #fff;
}

.progress_part .durations {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.progress_part #slider {
  width: 100%;
}

.controlls_btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
  background: rgb(226, 226, 226);
  border: 1px solid #d1d1d1;
}

.controlls_btns button {
  height: 50px;
  width: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 0.5em;
  background: none;
  transition: 0.5s;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
}

button#play_pause_btn {
  background: #6d6d6d;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
}

button#play_pause_btn i {
  color: #ffffff;
}

.controlls_btns button i {
  font-size: 1.3em;
}

.controlls_btns button i {
  color: rgb(5, 5, 5);
}

.required:after {
  content: " *";
  color: #FB1718;
}

/* tags page start */
.tag-box {
  border: 1px solid #d0d0d0;
  padding: 15px;
  border-radius: 5px;
}

.tag-box .tag-outer-box {
  background-color: #808082;
  color: #ffffff;
  border-radius: 50px;
  margin: 0 5px 14px 0;
  padding: 5px 10px;
  line-height: 1;
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  transition: 0.5s;
}


.icon-rount {
  background: #ffffff;
  color: #808082;
  width: 18px;
  height: 18px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  margin-left: 4px;
  font-size: 12px
}

.col-lg-2.d-flex.align-items-end {
  padding: 4px;
}

/* tags page end */
.custom-toggle {
  display: flex;
  /* vertical-align: middle; */
  align-items: center;
}

.custom-toggle input#flexSwitchCheckChecked {
  margin-top: 0;
}

.cate-outer-box {
  display: inline-block;
  margin: 0 12px 12px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 6px;
}

/* categeries page start */
input,
input::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 3;
}

label {
  font-size: 14px !important;
}

.cate-inner-box p {
  margin-bottom: 0 !important;
}

.categeries-box .cate-inner-box {
  background-color: #808082;
  color: #ffffff;
  border-radius: 3px;
  padding: 5px;
  line-height: 1;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 14px;
  transition: 0.5s;
  margin-bottom: 5px;

}

.categeries-box .cate-inner-box img {
  margin-right: 5px;
  border-radius: 60px;
  width: 25px;
  height: 25px;

}

.cate-outer-box p {
  margin: 0;
  font-size: 13px;
  margin-bottom: 3px;
}

.icon-box {
  background: #ffffff;
  color: #808082;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  margin-left: 4px;
  font-size: 12px;
  cursor: pointer;

}

.category-tabs .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  font-size: 16px;
  color: #19C958;
  background-color: #0d6efd00;
  font-weight: 500;
  padding-bottom: 2px;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-bottom-color: #19C958;
  width: fit-content;
}

.category-tabs .nav-link {
  margin: 0.5rem 1rem !important;
  padding: 0;
}

.category-tabs .nav-pills .nav-link {
  font-size: 16px;
  color: #555555;
}

/* categeries page end*/

.bg-white {
  background-color: #fff !important;
  /* height: 450px; */
  padding: 20px;
}

.form-switch .form-check-input {
  width: 2.3em;
  height: 1.4em;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: #bd062c;
  border-color: #bd062c;
}

.searchAdd-fields {
  display: flex;
  justify-content: end;
}

td.story-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}

.audio-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.check-box {
  display: flex;
  align-items: center;
}

.form-select {
  font-size: 14px;
  cursor: pointer;
}

.form-control {
  font-size: 14px;
  border: 1px solid #ced4da;
  background: #f8fafa;
}

.form-select {
  font-size: 14px;
  border: 1px solid #ced4da;
  /* background: #f8fafa; */
}

.alert_box .icon {
  height: 72px;
  width: 72px;
  color: #f23b26;
  border: 3px solid #f23b26;
  border-radius: 50%;
  line-height: 97px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto
}

.alert_box header {
  font-size: 25px;
  font-weight: 500;
  margin: 10px 0;
  text-align: center;
}

.alert_box p {
  font-size: 16px;
  text-align: center;
}

.alert_box .btns {
  margin-top: 20px;
}

.form-check-input {
  width: 1.2em;
  height: 1.2em;
}

.custom-toggle .form-check {
  margin-bottom: 0;
  min-height: 0;
  margin-left: 4px;
}

.drag-area {
  border: 2px dashed rgb(185, 185, 185);
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}

.drag-area.active {
  border: 2px solid rgb(0, 0, 0);
}

.drag-area .icon {
  font-size: 42px;
  color: #cfcfcf;
}

.drag-area .icon-edit {
  font-size: 42px;
  color: #cfcfcf;
  width: 150px;
  height: 150px;
}

.drag-area header {
  font-size: 15px;
  font-weight: 500;
  color: rgb(0, 0, 0);
}

.drag-area span {
  font-size: 15px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  margin: 10px 0 10px 0;
}

.drag-area button {
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  outline: none;
  background: #999999;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
}

.drag-area img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 5px;
}

.form-input-error {
  color: #dc3545;
}
.form-input-custerror {
  color: #dc3545;
}

.msl-wrp.msl-vars.undefined {
  width: 100%;
}

.msl {
  display: block;
  width: 100%;
  /* padding: 0.375rem 0.75rem; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.msl-input[data-placeholder]:not([data-placeholder=""]):empty:before {
  content: attr(data-placeholder);
  font-size: 14px;
}

.msl-input {
  margin: 2.3px;

}

.nodata-found {
  font-size: 18px;
  font-weight: 700;
  /* color: #0d6efd; */
  color: #d60000;
  margin-bottom: 0;
}

.msl-options {
  height: 200px;
}

.drag-area {
  position: relative;
}

input[type="file"] {
  font-size: 5px;
  line-height: 20px;
  padding-left: 5px;
  color: #bd062c;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.categoryCenter {
  text-align: center;
}

audio#audioval {
  width: 100%;
}

.user-upload-btn-wrapper img {
  width: 140px;
  height: 140px;
  border: #ddd solid 1px;
  border-radius: 5%;
  margin-top: 30px;

}

.user-upload-btn-wrapper1 img {
  width: 140px;
  height: 200px;
  border: #ddd solid 1px;
  border-radius: 5%;
  margin-top: 30px;

}

.user-upload-btn-wrapper {
  text-align: center;
}

.user-upload-btn-wrapper1 {
  text-align: center;
}


.popshowclim {
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate(-99.625px, -125px);
}

.alert_box .icon {
  height: 72px;
  width: 72px;
  color: #19C958;
  border: 3px solid #19C958;
  border-radius: 50%;
  line-height: 97px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.add-language span {
  color: #f00;
  margin-left: 3px;
}

.pointer span {
  cursor: pointer;
}

/* .msl-btn.msl-clear-btn.msl-flx.clear_items {
  display: none;
} */
.cate-inner-box p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 80%;
  cursor: pointer;
}

.cate-outer-box.clo-md-8 {
  flex: 0 0 auto;
  width: 15.6%;
}

.drop_clim {
  width: 220px;
}

.wid-th {
  width: 230px;
}

.mandatory {
  color: #ff0000;
}

.download-xl {
  color: #bd062c;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  border: 2px solid;
}

.custom-file {
  color: #19c958;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  border: 2px solid;
}

.custom-file-label {
  cursor: pointer;
  width: 100%;
}

.heading-text {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #000000;
  padding-top: 10px;

}

.Para-text {
  text-align: center;
}

.Para-text {
  text-align: center;
}

.me-auto {
  display: flex;
}

.search-clear {
  background-color: #bd062c;
  padding: 7px;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  height: 34px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.search-clear i{
  margin-right:5px;
}
.rdt {
  position: relative;
}

.search-clear:hover {
  background: #a70123;
}

.xl-buttons {
  position: absolute;
  left: 0;
  top: 16px;
}

.user-upload-btn-wrapper {
  position: relative;
  margin: auto;
  width: 140px;
  height: 140px;
  margin-bottom: 50px;
}

.user-upload-btn-wrapper1{
  position: relative;
  margin: auto;
  width: 140px;
  height: 200px;
  margin-bottom: 50px;
}

.table-custom-img img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.empty {
  display: none;
}

.datePickerBlock input {
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAbCAYAAABvCO8sAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTM1MUE2OEFCMjREMTFFQjkyQThDREFFQjA2QTAwRjUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTM1MUE2OEJCMjREMTFFQjkyQThDREFFQjA2QTAwRjUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpFMzUxQTY4OEIyNEQxMUVCOTJBOENEQUVCMDZBMDBGNSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFMzUxQTY4OUIyNEQxMUVCOTJBOENEQUVCMDZBMDBGNSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PlHCHYsAAAF4SURBVHjaYvz//z8DPQETA53B8LeQhRhFJ06ccL9x40a5gIDAhYCAgCJ0+YULF+4DpYWEhAQnqvjwz58/PEDKEUpjAFjCu3PnDg9FPrxw4YI+iH737p0piP7165cQTAwGQGJQpuOjR48iv3z5coqRkZFBX1//IjYzGXFlC1gwgQwiI6r2c3FxPQoLC0sgKkhBQUOBZWB93759kyMn0eyndiplpHdJw0IowVACDAwMLhJlISgOgRb2UxCH4OgAWuhESj50HJCSxt/fn0FQUJDh+vXrDCdPnsQp5ufnxyAkJMRw8+ZNhuPHj5Nf0rCysqLQuMTY2NjANDMzM2U+vHjxIgM3NzfDy5cv8YoB45yBh4eH4c2bN5RZePv2baLEgAltiFZPxCYaHx8fBhERERQxmiYaDg4ODDFSfQgqRx2vXLkCNgxbokFOIBQlGhUVlS9HjhwBs4E1PVUTDU4fSktLb3769Cl5NQKwAtbV1S0fFLXF8G8mAgQYAMeQuaPSpkZ7AAAAAElFTkSuQmCC) no-repeat 100%;
}
.error-msgs {
  position: absolute;
  top: 110%;
  right: 125px;
}
.datePickerBlock {
  position: relative;
}
.special-label {
  padding: 5px 0px;
}
.modal-body.play {
  box-shadow: none;
}
.modal-body.play {
  width: 100%;
  margin-left: 0px;
}
h5#exampleModalLabel {
  padding: 30px 0px;
}
img.icon-img {
  width: 20px;
  margin-right: 10px;
}
.special-label {
  display: none;
}
label.form-label.required {
  margin-bottom: 0;
  padding: 7px 0px;
}
.add-btn.submit {
    margin-top: 28px;
    margin-left: 8px;
    text-align: center;
}

/* asper page */
.common-heading-sec.pageno {
  /* margin-top: 20px; */
  /* margin-bottom: 30px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
span.row-per-pages {
  padding-right: 10px;
  margin-bottom: 9px;

}
.page-filter {
  display: flex;
  /* margin-top: 14px; */
  margin-bottom: 17px;
  margin-top: 12px;

}
.col-lg-6.assign-cus {
  display: flex;
 /* align-items: center*/;
  justify-content: end;
}

 .rdtPicker {
  z-index: 99999999 !important;
}

.passwordeye {
  position: absolute;
  right: 12px;
  top: 10px;
}

.passwordeye:hover {
  color: #bd062c;
  cursor: pointer;
}

.passwordeye:active {
  color: #bd062c;
}
.tbox {
  position: relative;
  
}
h4.common-title {
  text-align: center;
  border-bottom: 1px solid black;
}
.col-lg-2.mb-12.activestatus {
  display: flex;
  position: relative;
  
}
p.form-input-errors {
  position: absolute;
  color: #bd062c;
  
  top: 40px;
}
.main-content.callqueue {
  height: 75vh;
}
.main-content.editreschedule {
  height: 75vh;
}
.main-content.editqueue {
  height: 75vh;
}
/* .modal-body.editres {
  height: 65vh;
} */
/* .modal-body.editcallqueue {
  height: 40vh;
} */

.main-content.addcallqueue {
  height: 80vh;
}

.main-content-report{
  height: 80vh;
}
.main-content .history{
  height: 100vh;
}

.action-right-fix-list {
 /*  position: -webkit-sticky; */
  position: sticky;
  right: 0;
  background-color: #fff!important;
}
.action-right-fix-list2 {
  /*  position: -webkit-sticky; */
   position: sticky;
   right: 145px;
   background-color: #fff!important;
 }
.count {
  margin-left: 20px;
}


/* .sidebar123 {
  position: relative;
  width: 200px;
  height: 100%;
  background-color: #333;
  color: #fff;
  padding: 20px;
}

.dropdown123 {
  position: absolute;
  top: 100%;
  left: 0;
  width: 200px;
  background-color: #fff;
  color: #333;
  padding: 10px;
  border: 1px solid #ccc;
} */
/* .dropdown-btn {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}

.dropdown-container {
  display: none;
  background-color: #262626;
  padding-left: 8px;
}
.fa-caret-down {
  float: right;
  padding-right: 8px;
} */

button.btn.btn-primary.dropdown-toggle i {
  margin-left: 12px;
  margin-right: 10px;
  font-size: 16px;
}
button.btn.btn-primary.dropdown-toggle {
  background: none;
  border: none;
  font-size: 14px;
  width: 100%;
  text-align: left;
}
.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow:none !important;
}
.btn-check:focus+.btn-primary, .btn-primary:focus {
  box-shadow: none !important;
}
button.btn.btn-primary.dropdown-toggle .dropdown-toggle::after {
  position: absolute !important;
  right: 22px !important;
  top: 16px !important;
  font-size: 18px !important;
}
.dropdown-toggle::after {
  position: absolute;
  right: 22px;
  top: 16px;
  font-size: 18px;
}

i.fa-solid.fa-gear {
  margin-left: 0px !important;
}

.countconfig {
  display: flex;
  align-items: center;
  /* width: 100%; */
}

label.col-form-label {
  width: 50%;
  margin-left: 10px;

}






ul.list-unstyled.components::-webkit-scrollbar {
  width: 14px;
}


ul.list-unstyled.components::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 

ul.list-unstyled.components::-webkit-scrollbar-thumb {
  background: #505050; 
  
}


ul.list-unstyled.components::-webkit-scrollbar-thumb:hover {
  background: #3d3d3d; 
}

ul.list-unstyled.components {
  height: 600px;
  overflow: hidden;
 overflow-y: auto;
  margin: 0px 0px;
}

.upload {
  margin-top: 32px;
}
.main-contents {
  padding: 30px;
}

.main-contents .back-heading{
margin-bottom: 0px !important;
}

button.btn.btn-smloutline-danger.me-2 {

  background: #bd062c;
  color: #fff;
  font-size: 18px;
}
.successpopup {
  text-align: center;
  padding: 30px;
  background: white;
  width: 100%;
  display: flow;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 25%;
  position: absolute;
  transform: translate(155%, 83%);
}


.col-xl-2.form-group.datePickerBlock.recycle {
  display: flex;
  align-items: end;
}

.models-box.recycle {
  max-width: 900px!important;
  height: 440px !important;
}
.head-loadmore {
  border: solid 2px #be0004;
  padding: 6px 8px;
  color: #bc0001;
  cursor: pointer;
}
.loadmore a:hover {
  color: #fff;
  background-color: #a70123;
}
.loadmore {
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadpopup {
  text-align: center;
  padding: 30px;
}
.custaddrecycle {
  display: flex;
  align-items: center;
  justify-content: end;
}
.col-lg-2.activestatus {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
}
.col-lg-1.activestatus1 {
  position: absolute;
  display: flex;
  align-items: center;
  right: 0px;
  justify-content: end;
}
.PageselectAll span {
  margin-right: 10px;
}

.PageselectAll {
  font-weight: 500;
  margin-left: 10px;
}
.customer-table {
  margin-top: 50px;
  
}
.col-lg-2.phone {
  padding: 0 10px 0 0 !important;
}
.datePickerBlock {
  position: relative;
  padding: 0 10px 0 0 !important;
}
.col-lg-2.filterfind {
  display: flex;
  align-items: center;
  justify-content: left;
}

thead.assign-thead{
  border-bottom-style: hidden !important;
  background: #002768 !important;
  color: #fff !important;
}

.rounded.table-responsive.customer-table.editassign-table {
  width: 50%;
 /*  margin: auto; */
  border: none !important;
}
span.assign-selectalls {
  margin-right: 5px;
}

/* ----------new css----------- */

/* .btn-toggle-nav {
  padding-left: 1rem; 
}

.sidebar-link {
  display: flex;
  align-items: center;
}

.sidebar-link .hide-menu {
  margin-left: 0.5rem; 
}

.sidebar-link {
    transition: background-color 0.3s; 
    color: white;
    width: 200px;
  
}

.sidebar-link:hover {
  background-color: #bd062c;
  color: white;
  width: 200px;
} */



button#Settings_outer {
  padding: 8px 16px;
  line-height: 30px;
  border-radius: 5px;
  font-size: 14px;
  position: relative;
  font-weight: 400;
  display: flex;
  width: 100%;
}
button#Settings_outer i {
  color: #ffff ;
  margin-right: 12px;
}
span.hide-menu {
  color: #fff;
}
button#Settings_outer:hover {
  color: #ffffff;
  background-color: #bd062c;
  border-left-style: solid;
}
.rotate {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}
.small-font {
  font-size: 5px;  /* You can adjust this value as needed */
}
.fromdate {
  width: 260px;
  margin-left: 10px;
}
.todates {
  width: 260px;
}
.dateandtime {
  margin-top: 20px;
}
.collg1 {
  width:70px;
  text-align:center
}
.green{
  color:green;
}
.orange{
color: red;
}
.z-index{
  z-index: 111;
}
