@media only screen and (max-width: 1599px) {
    .error-msgs {
        top: 110%;
        right: 0;
    }

}

@media only screen and (max-width: 1399px) {
    #sidebar.active {
        width: 80px;
        height: 100vh !important;
        position: fixed !important;
        /* overflow: visible !important; */
        top: 0 !important;
        z-index: 666;
        float: left !important;
        bottom: 0 !important;
    }
    .error-msgs {
        top: 62%;
    }
    .form-input-error {
        font-size: 12px;
    }
    .search-clear {
        background-color: #bd062c;
        padding: 7px;
     /*    margin-bottom: 8px; */
        color: #ffffff;
        border-radius: 3px;
        cursor: pointer;
        font-size: 14px;
        text-align: center;
        height: fit-content;
    }
    .search-clear i{
        margin-right: 0px !important;
    }
    .add-btn {
        text-align: center;
    }
    .add-btn i{
        margin-right: 0px !important;
    }
    .table td, .table th {
        min-width: 145px;
    }
    /* width */
    .table-responsive::-webkit-scrollbar {
      width: 4px;
    }
    
    /* Track */
    .table-responsive::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
     
    /* Handle */
    .table-responsive::-webkit-scrollbar-thumb {
      background: #002768; 
    }
    
    /* Handle on hover */
    .table-responsive::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }
    .dashboard-sec .custom-column {
        width: 24%;
    }
    .error-msgs {
        top: 110%;
        right: 52px;
    }
}

@media only screen and (max-width: 1199px) {
    #sidebar.active {
        width: 80px;
        height: 100vh !important;
        position: fixed !important;
        /* overflow: visible !important; */
        top: 0 !important;
        z-index: 666;
        float: left !important;
        bottom: 0 !important;
    }
    
    .form-input-error {
        font-size: 12px;
    }
    .error-msgs {
        right: 14%;
    }
    .dashboard-sec .custom-column {
        width: 32%;
    }
    .download-xl {
        font-size: 12px;
    }
    label.custom-file-label {
        font-size: 12px !important;
    }
    .add-btn.submit {
        margin-left: -6px;
    }
}

@media only screen and (max-width: 991px) {
    .custom-column {
        width: 50% !important;
    }

    #sidebar.active {
        width: 80px;
        height: 100vh !important;
        position: fixed !important;
        /* overflow: visible !important; */
        top: 0 !important;
        z-index: 666;
        float: left !important;
        bottom: 0 !important;
    }
    .heading-text {
        margin-bottom: 28px;
    }
    #sidebar {
        width: 170px;
    }
    #content {
        width: calc(100% - 170px);
    }
    .img-fluid {
        max-width: 100%;
    }
    .table td, .table th {
        min-width: 140px;
    }
    .common-heading-sec.cus {
        margin-top: 24px;
    }
    .xl-buttons {
        top: 50px;
    }
    .modal-body.map {
        height: 512px;
    }
    #sidebar ul li a {
        padding: 8px 0px;
    }
}

@media only screen and (max-width: 767px) {
    .custom-column {
        width: 33% !important;
    }
}

@media only screen and (max-width: 575px) {
    .custom-column {
        width: 50% !important;
    }
}

@media only screen and (max-width: 420px) {
    .custom-column {
        width: 100% !important;
    }
}